import React from 'react';
import { Typography, Box } from '@material-ui/core';
import Layout from '../../../components/layout';
function RSVPYESSuccess() {
  return (
    <Layout>
      <Box mt={10}>
        <Box my={5}>
          <Typography align='center' variant='body1' gutterBottom>
            Thank you for confirming your attendance to the fourth edition of the
          </Typography>
          <Typography align='center' variant='body1' gutterBottom>
            Global Manufacturing & Industrialisation Summit (#GMIS2021) and the GMIS Week.
          </Typography>
        </Box>
        <Box mb={2}>
          <Typography variant='body1' align='center' gutterBottom>
            We look forward to welcoming you at EXPO’s Dubai Exhibition
          </Typography>
        </Box>
        <Box mb={2}>
          <Typography variant='body1' align='center' gutterBottom>
            Centre from November 22-27, 2021.
          </Typography>
        </Box>

        <Box mt={5}>
          <img width='100%' src='../images/gmis-static-banner-cropped.png' />
        </Box>
      </Box>
    </Layout>
  );
}
export default RSVPYESSuccess;
